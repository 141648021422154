import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { OverlayTriggerProps } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export const OVERLAY_TRIGGERS: OverlayTriggerProps['trigger'] = ['hover', 'focus'];

export async function copyToClipboard(text: string) {
    try {
        await navigator.permissions.query({
            //@ts-ignore
            name: "clipboard-write"
        });
    } catch (error) {
        console.error(error);
    }
    try {
        navigator.clipboard.writeText(text);
    } catch (error) {
        console.error(error);
    }
}

export const CALCULATOR_URI = 'Calculator:///';
export function openCalculator() {
    window.open(CALCULATOR_URI);
}

export function useMobileOrientation() {
    const [state, setState] = useState(() => {
        const orientation = window.innerWidth > window.innerHeight ? 90 : 0;
        return {
            isPortrait: orientation === 0,
            isLandscape: orientation === 90,
            orientation: orientation === 0 ? 'portrait' : 'landscape'
        }
    });
    const handleOrientationChange = useCallback(() => {
        const orientation = window.innerWidth > window.innerHeight ? 90 : 0;
        const next = {
            isPortrait: orientation === 0,
            isLandscape: orientation === 90,
            orientation: orientation === 0 ? 'portrait' : 'landscape'
        }
        state.orientation !== next.orientation && setState(next);
    }, [state.orientation]);
    useEffect(() => {
        if (typeof window !== undefined && isMobile) {
            handleOrientationChange();
            window.addEventListener("load", handleOrientationChange, false);
            window.addEventListener("resize", handleOrientationChange, false);
        }
        return () => {
            window.removeEventListener("resize", handleOrientationChange, false);
            window.removeEventListener("load", handleOrientationChange, false);
        }
    }, [handleOrientationChange]);
    return state;
}

export function useAddChild<T extends Element>(parent?: T) {
    const container = useMemo(() => document.createElement('div'), []);
    useLayoutEffect(() => {
        const p = parent || document.body;
        p.appendChild(container);
        return () => { p.removeChild(container) };
    }, [parent, container]);
    return container;
}

export function useMutationObserver<T extends Node>(ref: React.RefObject<T>, callback: MutationCallback, options?: MutationObserverInit) {
    const observer = new MutationObserver(callback);
    observer.observe(ref.current!, options);
    return () => observer.disconnect();
}

export function bufferToBase64DataUrl(buffer: Buffer, mimeType: string) {
    return 'data:' + mimeType + ';base64,' + buffer.toString('base64');
}

export function isElementInViewport(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function useWindowEvent<K extends keyof WindowEventMap>(type: K, listener: (this: Window, ev: WindowEventMap[K]) => any, options?: boolean | AddEventListenerOptions | undefined) {
    useEffect(() => {
        window.addEventListener(type, listener, options);
        return () => window.removeEventListener(type, listener, options);
    }, [type, listener, options]);
}
