import React, { lazy, Suspense } from 'react';
import { Route, Switch } from "react-router";
import { BrowserRouter, useRouteMatch } from "react-router-dom";
import './App.scss';
import { SpinnerCenter } from './common';

const Video1 = lazy(() => import('./about/Video1'));
const Video1Thumbnail = lazy(() => import('./about/V1T'));
const Video2 = lazy(() => import('./about/Video2'));
const Video2Thumbnail = lazy(() => import('./about/V2T'));

const NotFound = React.memo(() => {
  const match = useRouteMatch<{ assetName: string }>('/assets/:assetName');
  return <h4>Asset <strong>{match?.params.assetName}</strong> Not Found</h4>;
})

const AssetRoute = React.memo(() => {
  return (
    <Suspense fallback={<SpinnerCenter className="d100" />}>
      <BrowserRouter>
        <Switch>
          <Route path="/assets/about-video.mp4">
            <Video1 />
          </Route>
          <Route path="/assets/about-video-poster.png">
            <Video1Thumbnail />
          </Route>
          <Route path="/assets/learning-model-video.mp4">
            <Video2 />
          </Route>
          <Route path="/assets/learning-model-video-poster.png">
            <Video2Thumbnail />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
})

export default AssetRoute;