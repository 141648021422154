import { IonIcon } from '@ionic/react';
import _ from 'lodash';
import React, { memo } from "react";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";


export const SpinnerCenter = memo(({ className }: { className?: string }) =>
    <div className={(isMobile ? "absolute flex center center-content" : "flex center center-content") + (className || "")}>
        <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
        </Spinner>
    </div>
);

/**
 * visit https://ionicons.com/ to search for an icon
 * pass name, icon or src prop
 */
export const Icon = React.memo(({ name, icon, className, color, ...props }: Parameters<typeof IonIcon>[0] & { size?: 'small' | 'large' }) => {
    return (
        <IonIcon
            {...props}
            className={"icon " + (className || "")}
            style={color ? { color } : undefined}
            icon={icon || (name && (require('ionicons/icons')[_.camelCase(name)] || require('./icons')[_.camelCase(name)]))}
            title=""
        />
    );
});
