import 'bootstrap/dist/css/bootstrap.min.css';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import './App.scss';
import AssetRoute from './AssetRoute';
import { SpinnerCenter } from './common';

const About = lazy(() => import('./about/About'));

const App = React.memo(() => {
  return (
    <Suspense fallback={<SpinnerCenter className="fill" />}>
      <BrowserRouter>
        <Switch>
          <Route path="/assets/*">
            <AssetRoute />
          </Route>
          <Route path={["/", "/about"]}>
            <About />
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
})

export default App;